/* styles for nullify-website/styles/about.scss */
._split_1nikmi {
	border-top: 2px dotted grey;
}

._officers_1nikmi {
	margin-top: 10vh;
}

._strike_1nikmi {
    display: block;
    text-align: center;
    overflow: hidden;
	white-space: nowrap; 
	margin-top: 5vh;
	margin-bottom: 3vh;
}

._strike_1nikmi > h3 {
    position: relative;
    display: inline-block;
}

._strike_1nikmi > h3:before,
._strike_1nikmi > h3:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: grey;
}

._strike_1nikmi > h3:before {
    right: 100%;
    margin-right: 15px;
}

._strike_1nikmi > h3:after {
    left: 100%;
    margin-left: 15px;
}
/* styles for nullify-website/styles/admin/announcement.scss */
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

/* end ./page-wrapper */
._elem_45w7ii {
  height: 15px;
  width: 15px;
  display: inline-block;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 8px;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;

  &:first-child {
    margin-left: 10px;
  }

  &._elem-close_45w7ii {
    background-color: #ff5d5b;
    border-color: #ee4644;
  }

  &._elem-minimize_45w7ii {
    background-color: #ffbb46;
    border-color: #f1ad39;
  }

  &._elem-fullscreen_45w7ii {
    background-color: #00c74f;
    border-color: #01b849;
  }
}

._terminal-section_45w7ii {
  position: relative;
  display: block;
}

._terminal-outer_45w7ii {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  font-weight: thin;
  font-family: 'Inconsolata', monospace;
  color: #fff;
  width: 100%;
  text-shadow: none;

  ._terminal-container_45w7ii {
    ._terminal-header_45w7ii {
      background-color: #c5c5c5;

      ._terminal-controls_45w7ii {
        display: inline-block;

        ._elem_45w7ii {
          float: left;
        }
      }
    }

    ._terminal-body_45w7ii {
      background-color: #303030;
      padding: 10px;

      ._terminal-body-inner_45w7ii {
        min-height: 350px;
        color: #fff;

        p {
          margin: 0 0 4px;
        }

        ._terminal-user_45w7ii {
          margin-right: 0px;
        }
      }
    }
  }
}


// Typed.js blinking cursor css
.typed-cursor {
  opacity: 1;
  -webkit-animation: _blink_45w7ii 0.7s infinite;
  -moz-animation: _blink_45w7ii 0.7s infinite;
  animation: _blink_45w7ii 0.7s infinite;
}


@keyframes _blink_45w7ii {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes _blink_45w7ii {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes _blink_45w7ii {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// Toggle button Styling
._toggle-button_45w7ii {
  position: absolute;
  top: 45%;
  right: 1%;
  font-size: 20px;
  color: grey;
}

._toggle-button_45w7ii:hover {
  color: white;
}

._append_45w7ii {
  margin-right: -5px;
}

._code-edit_45w7ii{
	width: 100%;
	height: 190px;
	margin-bottom: 0px;
	background-color: #333333;
	color: white;
}

._save_45w7ii {
	position: absolute;
	bottom: 2%;
	right: 0%;
}

._display-none_45w7ii {
	display: none;
}

/* styles for nullify-website/styles/admin/calendar.scss */
._code_df6qm1>pre {
  margin-bottom: 0;
}

._button_df6qm1 {
  position: absolute;
  top: 0;
  right: 0;
}

/* styles for nullify-website/styles/admin/officers.scss */
._card-hover_q8upvu:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

._card-hover_q8upvu:hover:after {
  opacity: 1;
}

/* styles for nullify-website/styles/admin/resources.scss */
._newTag_u84b6:hover {
	background-color: gray;
}
/* styles for nullify-website/styles/app.scss */

@import "ember-cli-bootswatch/bootswatch";

// Hides the scroll bar on webkit enabled browsers
// This is simply because I hate when pages transition and they are off
// by just a few pixels because of that daMN SCROLL BAR!
::-webkit-scrollbar {
  display: none;
}

html, body {
	height: 100% !important;
}

// Allows {{liquid-outlet}} to obtain 100% height
.liquid-child {
	height: 100% !important;
}

// Sets ember-view class to full height
body > .ember-view {
  height: 100% !important;
    display: flex;
    flex-direction: column;
}

// These are custom made global transition classes
.fade-in{
	-webkit-animation: _fadein_1dg45n 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: _fadein_1dg45n 2s; /* Firefox < 16 */
	-ms-animation: _fadein_1dg45n 2s; /* Internet Explorer */
	-o-animation: _fadein_1dg45n 2s; /* Opera < 12.1 */
	animation: _fadein_1dg45n 2s;
}

@keyframes _fadein_1dg45n {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes _fadein_1dg45n {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes _fadein_1dg45n {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes _fadein_1dg45n {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes _fadein_1dg45n {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slide-in-from-left{
	-webkit-animation: _slideInFromLeft_1dg45n 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: _slideInFromLeft_1dg45n 2s; /* Firefox < 16 */
	-ms-animation: _slideInFromLeft_1dg45n 2s; /* Internet Explorer */
	-o-animation: _slideInFromLeft_1dg45n 2s; /* Opera < 12.1 */
	animation: _slideInFromLeft_1dg45n 2s;
}

@keyframes _slideInFromLeft_1dg45n {
  from {
    transform: translateX(-25%);
  }

  to {
    transform: translateX(0%);
  }
}

/* Firefox < 16 */
@-moz-keyframes _slideInFromLeft_1dg45n {
  from {
    transform: translateX(-25%);
  }

  to {
    transform: translateX(0%);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes _slideInFromLeft_1dg45n {
  from {
    -webkit-transform: translateX(-25%);
  }

  to {
    -webkit-transform: translateX(0%);
  }
}

/* Internet Explorer */
@-ms-keyframes _slideInFromLeft_1dg45n {
  from {
    transform: translateX(-25%);
  }

  to {
    transform: translateX(0%);
  }
}

/* Opera < 12.1 */
@-o-keyframes _slideInFromLeft_1dg45n {
  from {
    transform: translateX(-25%);
  }

  to {
    transform: translateX(0%);
  }
}

.slide-in-from-right{
	-webkit-animation: _slideInFromRight_1dg45n 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: _slideInFromRight_1dg45n 2s; /* Firefox < 16 */
	-ms-animation: _slideInFromRight_1dg45n 2s; /* Internet Explorer */
	-o-animation: _slideInFromRight_1dg45n 2s; /* Opera < 12.1 */
	animation: _slideInFromRight_1dg45n 2s;
}

@keyframes _slideInFromRight_1dg45n {
  from {
    transform: translateX(25%);
  }

  to {
    transform: translateX(0%);
  }
}

/* Firefox < 16 */
@-moz-keyframes _slideInFromRight_1dg45n {
  from {
    transform: translateX(25%);
  }

  to {
    transform: translateX(0%);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes _slideInFromRight_1dg45n {
  from {
    -webkit-transform: translateX(25%);
  }

  to {
    -webkit-transform: translateX(0%);
  }
}

/* Internet Explorer */
@-ms-keyframes _slideInFromRight_1dg45n {
  from {
    transform: translateX(25%);
  }

  to {
    transform: translateX(0%);
  }
}

/* Opera < 12.1 */
@-o-keyframes _slideInFromRight_1dg45n {
  from {
    transform: translateX(25%);
  }

  to {
    transform: translateX(0%);
  }
}

.slide-in-from-top{
	-webkit-animation: _slideInFromTop_1dg45n 1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: _slideInFromTop_1dg45n 2s; /* Firefox < 16 */
	-ms-animation: _slideInFromTop_1dg45n 2s; /* Internet Explorer */
	-o-animation: _slideInFromTop_1dg45n 2s; /* Opera < 12.1 */
	animation: _slideInFromTop_1dg45n 2s;
}

@keyframes _slideInFromTop_1dg45n {
  from {
    transform: translateY(-25%);
  }

  to {
    transform: translateY(0%);
  }
}

/* Firefox < 16 */
@-moz-keyframes _slideInFromTop_1dg45n {
  from {
    transform: translateY(-25%);
  }

  to {
    transform: translateY(0%);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes _slideInFromTop_1dg45n {
  from {
    -webkit-transform: translateY(-25%);
  }

  to {
    -webkit-transform: translateY(0%);
  }
}

/* Internet Explorer */
@-ms-keyframes _slideInFromTop_1dg45n {
  from {
    transform: translateY(-25%);
  }

  to {
    transform: translateY(0%);
  }
}

/* Opera < 12.1 */
@-o-keyframes _slideInFromTop_1dg45n {
  from {
    transform: translateY(-25%);
  }

  to {
    transform: translateY(0%);
  }
}

@import "ember-bootstrap/bootstrap";

@import 'ember-basic-dropdown';

/* styles for nullify-website/styles/application.scss */
._navbar_1qmr18 {
	height: 10%;
}

._content_1qmr18 {
	flex-grow: 1;
}
/* styles for nullify-website/styles/calendar.scss */
._code_11c6ib > pre {
	margin-bottom: 0;
}

._button_11c6ib {
	position: absolute;
	top: 0;
	right: 0;
}

._info-button_11c6ib {
	position: absolute;
	right: 0;
	bottom: 0;
	margin-bottom: 0.5rem;
	margin-right: 0.5rem;
}

._alt-text_11c6ib {
	margin-top: 1rem;
}
/* styles for nullify-website/styles/components/admin/calendar.scss */
$font-size: 16px !default;
$border-radius: 3px !default;
$date-picker-color-primary: #27963c !default;
$date-picker-color-secondary: #222222 !default;
$date-picker-color-background: #222222 !default;

@import "ember-date-components/addon";

.date-picker{
	background: #303030;
}

.date-picker__day {
	border-color: #303030;
}

.time-picker__dropdown {
	background: #303030;
}

.time-picker__dropdown__item {
  background: #303030;
  color: white;
}

.time-picker__input {
	background: #303030;
	color: white;
	border: none;
}

.ember-basic-dropdown-content {
	background: #303030;
}

.form-control {
	background: #303030;
	color: white;
}

.form-control:focus {
  background: #636363;
  color: white;
}

.form-control:disabled {
  background: #636363;
  color: white;
}
/* styles for nullify-website/styles/components/admin/officer-row.scss */
th input {
  min-width: 10px;
  max-width: 100%;
  width: 100%;
}

td input {
  min-width: 10px;
  max-width: 100%;
  width: 100%;
}
/* styles for nullify-website/styles/components/fake-terminal.scss */
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

/* end ./page-wrapper */
._elem_1yhzh4 {
  height: 15px;
  width: 15px;
  display: inline-block;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 8px;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;

  &:first-child {
    margin-left: 10px;
  }

  &._elem-close_1yhzh4 {
    background-color: #ff5d5b;
    border-color: #ee4644;
  }

  &._elem-minimize_1yhzh4 {
    background-color: #ffbb46;
    border-color: #f1ad39;
  }

  &._elem-fullscreen_1yhzh4 {
    background-color: #00c74f;
    border-color: #01b849;
  }
}

._terminal-section_1yhzh4 {
  position: relative;
  display: block;
}

._terminal-outer_1yhzh4 {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  font-weight: thin;
  font-family: 'Inconsolata', monospace;
  color: #fff;
  width: 75%;
  text-shadow: none;
  margin-top: 10vh;
  margin-bottom: 10vh;

  ._terminal-container_1yhzh4 {
    ._terminal-header_1yhzh4 {
      background-color: #c5c5c5;

      ._terminal-controls_1yhzh4 {
        display: inline-block;

        ._elem_1yhzh4 {
          float: left;
        }
      }
    }

    ._terminal-body_1yhzh4 {
      background-color: #303030;
      padding: 10px;

      ._terminal-body-inner_1yhzh4 {
        min-height: 350px;
        color: #fff;

        p {
          margin: 0 0 4px;
        }

        ._terminal-user_1yhzh4 {
          margin-right: 0px;
        }
      }
    }
  }
}


// Typed.js blinking cursor css
.typed-cursor {
  opacity: 1;
  -webkit-animation: _blink_1yhzh4 0.7s infinite;
  -moz-animation: _blink_1yhzh4 0.7s infinite;
  animation: _blink_1yhzh4 0.7s infinite;
}


@keyframes _blink_1yhzh4 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes _blink_1yhzh4 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes _blink_1yhzh4 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// Skip button Styling
._skip-button_1yhzh4{
	position: absolute;
	bottom: 0;
	right: 0;
	color: grey;
}

._skip-button_1yhzh4:hover {
	color: white;
}

._append_1yhzh4 {
	margin-right: -5px;
}


/* styles for nullify-website/styles/components/footer.scss */
._footer_uta2kd {
	padding-top: 20px;
	padding-bottom: 20px;
}

/* styles for nullify-website/styles/components/matrix-container.scss */
._hide_11swkb {
  	display: none;
}

._matrix_11swkb {
	background-color: #222222;
	-webkit-animation: _blur_11swkb 10s infinite;
	animation: _blur_11swkb 10s infinite;
	-webkit-animation-delay: 10s;
	animation-delay: 10s;
	margin: 0;
	position: absolute;
	width: 100%;
}

._forward_11swkb {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1;
	width: 100%;
	overflow-y: scroll;
	height: 100vh;
	padding-bottom: 150px;
	--webkit-overflow-scrolling: touch;
}

@-webkit-keyframes _blur_11swkb {
  0% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  2% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  5% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  7% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  10% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  12% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  58% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  60% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  62% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  65% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  67% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  69% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  72% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }
}

@keyframes _blur_11swkb {
  0% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  2% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  5% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  7% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  10% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  12% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  58% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  60% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  62% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  65% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  67% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  69% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  72% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }
}
/* styles for nullify-website/styles/components/navbar.scss */
a._skip-main_kegw28 {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

a._skip-main_kegw28:focus,
a._skip-main_kegw28:active {
  color: #fff;
  background-color: #303030;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  overflow: auto;
  margin: 10px 10px;
  padding: 5px;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

._user-icon_kegw28 {
	height: 3rem;
}

._wrap_kegw28 {
	position: relative;
}

._overlay_kegw28 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  opacity: 0;
  transition: .25s ease;
  background-color: #171717;
  border-radius: 50% !important;
}

._wrap_kegw28:hover ._overlay_kegw28 {
  opacity: 0.9;
}

._text_kegw28 {
  color: white;
  font-size: 0.85rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
/* styles for nullify-website/styles/components/officer-card.scss */
._officer-card_3wsrwz {
	width: 20rem;
}

._image-holder_3wsrwz {
	padding-right: 0px;
}

._text-holder_3wsrwz {
	padding-left: 10px;
	margin-top: 8px;
}

._profile-picture_3wsrwz {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

._top-row_3wsrwz {
	margin-bottom: 5px;
}

/* styles for nullify-website/styles/components/resource-card.scss */
._thumbnail_bfuhor iframe {
  width: 1440px;
  height: 900px;
}

._thumbnail_bfuhor {
  position: relative;
  -ms-zoom: 0.20;
  -moz-transform: scale(0.20);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.20);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.20);
  -webkit-transform-origin: 0 0;
}

._thumbnail_bfuhor:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

._thumbnail-container_bfuhor {
  width: calc(1440px * 0.25);
  height: calc(900px * 0.25);
  display: inline-block;
  overflow: hidden;
  position: relative;
}

._thumbnail_bfuhor iframe {
  opacity: 0;
  transition: all 300ms ease-in-out;
}

._thumbnail-container_bfuhor::before {
  position: absolute;
  left: calc(50% — 16px);
  top: calc(50% — 18px);
  opacity: 0.2;
  display: block;
  -ms-zoom: 2;
  -o-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);

  content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJwaG90b18xXyI+PHBhdGggZD0iTTI3LDBINUMyLjc5MSwwLDEsMS43OTEsMSw0djI0YzAsMi4yMDksMS43OTEsNCw0LDRoMjJjMi4yMDksMCw0LTEuNzkxLDQtNFY0QzMxLDEuNzkxLDI5LjIwOSwwLDI3LDB6ICAgIE0yOSwyOGMwLDEuMTAyLTAuODk4LDItMiwySDVjLTEuMTAzLDAtMi0wLjg5OC0yLTJWNGMwLTEuMTAzLDAuODk3LTIsMi0yaDIyYzEuMTAyLDAsMiwwLjg5NywyLDJWMjh6IiBmaWxsPSIjMzMzMzMzIi8+PHBhdGggZD0iTTI2LDRINkM1LjQ0Nyw0LDUsNC40NDcsNSw1djE4YzAsMC41NTMsMC40NDcsMSwxLDFoMjBjMC41NTMsMCwxLTAuNDQ3LDEtMVY1QzI3LDQuNDQ3LDI2LjU1Myw0LDI2LDR6ICAgIE0yNiw1djEzLjg2OWwtMy4yNS0zLjUzQzIyLjU1OSwxNS4xMjMsMjIuMjg3LDE1LDIyLDE1cy0wLjU2MSwwLjEyMy0wLjc1LDAuMzM5bC0yLjYwNCwyLjk1bC03Ljg5Ni04Ljk1ICAgQzEwLjU2LDkuMTIzLDEwLjI4Nyw5LDEwLDlTOS40NCw5LjEyMyw5LjI1LDkuMzM5TDYsMTMuMDg3VjVIMjZ6IE02LDE0LjZsNC00LjZsOC4wNjYsOS4xNDNsMC41OCwwLjY1OEwyMS40MDgsMjNINlYxNC42eiAgICBNMjIuNzQsMjNsLTMuNDI4LTMuOTU1TDIyLDE2bDQsNC4zNzlWMjNIMjIuNzR6IiBmaWxsPSIjMzMzMzMzIi8+PHBhdGggZD0iTTIwLDEzYzEuNjU2LDAsMy0xLjM0MywzLTNzLTEuMzQ0LTMtMy0zYy0xLjY1OCwwLTMsMS4zNDMtMywzUzE4LjM0MiwxMywyMCwxM3ogTTIwLDhjMS4xMDIsMCwyLDAuODk3LDIsMiAgIHMtMC44OTgsMi0yLDJjLTEuMTA0LDAtMi0wLjg5Ny0yLTJTMTguODk2LDgsMjAsOHoiIGZpbGw9IiMzMzMzMzMiLz48L2c+PC9zdmc+");
}

._card-hover_bfuhor:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

._card-hover_bfuhor:hover:after {
  opacity: 1;
}
/* styles for nullify-website/styles/components/terminal.scss */
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

/* end ./page-wrapper */
._elem_p2q9ks {
  height: 15px;
  width: 15px;
  display: inline-block;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 8px;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;

  &:first-child {
    margin-left: 10px;
  }

  &._elem-close_p2q9ks {
    background-color: #ff5d5b;
    border-color: #ee4644;
  }

  &._elem-minimize_p2q9ks {
    background-color: #ffbb46;
    border-color: #f1ad39;
  }

  &._elem-fullscreen_p2q9ks {
    background-color: #00c74f;
    border-color: #01b849;
  }
}

._terminal-section_p2q9ks {
  position: relative;
  display: block;
}

._terminal-outer_p2q9ks {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  font-weight: thin;
  font-family: 'Inconsolata', monospace;
  color: #fff;
  width: 100%;
  text-shadow: none;

  ._terminal-container_p2q9ks {
    ._terminal-header_p2q9ks {
      background-color: #c5c5c5;

      ._terminal-controls_p2q9ks {
        display: inline-block;

        ._elem_p2q9ks {
          float: left;
        }
      }
    }

    ._terminal-body_p2q9ks {
      background-color: #303030;
      padding: 10px;

      ._terminal-body-inner_p2q9ks {
        min-height: 350px;
        color: #fff;

        p {
          margin: 0 0 4px;
        }

        ._terminal-user_p2q9ks {
          margin-right: 0px;
        }
      }
    }
  }
}


// Typed.js blinking cursor css
.typed-cursor {
  opacity: 1;
  -webkit-animation: _blink_p2q9ks 0.7s infinite;
  -moz-animation: _blink_p2q9ks 0.7s infinite;
  animation: _blink_p2q9ks 0.7s infinite;
}


@keyframes _blink_p2q9ks {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes _blink_p2q9ks {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes _blink_p2q9ks {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// Skip button Styling
._skip-button_p2q9ks {
  position: absolute;
  bottom: 0;
  right: 0;
  color: grey;
}

._skip-button_p2q9ks:hover {
  color: white;
}

._append_p2q9ks {
  margin-right: -5px;
}

/* styles for nullify-website/styles/contact.scss */
._logo-text_r9masr {
	color: white;
	text-decoration: none;
	font-size: 20px;
	padding: 10px;
}

._contact-card_r9masr {
	padding: 20px; 
}

._mail-list_r9masr {
	width: 75%;
}

// Styling for the map component
.ember-google-map {
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
}

._map-wrapper_r9masr{
	height: 100%;
}

._map-wrapper_r9masr > div > div > div > iframe {
  background-color: #212121;
}
/* styles for nullify-website/styles/contribute/resource-name.scss */
input:focus {
  outline: 0;
}

input._question_nmpc8f,
textarea._question_nmpc8f {
  font-size: 48px;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  width: 80%;
  background: rgba(0, 0, 0, 0);
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden;
  /* Hack to make "rows" attribute apply in Firefox. */
}

/* Underline and Placeholder */

input._question_nmpc8f+label,
textarea._question_nmpc8f+label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 10%;
  border-top: 1px solid red;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}

input._question_nmpc8f:focus+label,
textarea._question_nmpc8f:focus+label {
  width: 80%;
}

input._question_nmpc8f:focus,
input._question_nmpc8f:valid {
  padding-top: 35px;
}

textarea._question_nmpc8f:valid,
textarea._question_nmpc8f:focus {
  margin-top: 35px;
}

input._question_nmpc8f:focus+label>span,
input._question_nmpc8f:valid+label>span {
  top: -100px;
  font-size: 22px;
  color: #FFFFFF;
}

textarea._question_nmpc8f:focus+label>span,
textarea._question_nmpc8f:valid+label>span {
  top: -150px;
  font-size: 22px;
  color: #FFFFFF;
}

input._question_nmpc8f:valid+label,
textarea._question_nmpc8f:valid+label {
  border-color: green;
}

input._question_nmpc8f:invalid,
textarea._question_nmpc8f:invalid {
  box-shadow: none;
}

input._question_nmpc8f+label>span,
textarea._question_nmpc8f+label>span {
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #FFFFFF;
  font-size: 48px;
  top: -66px;
  left: 0px;
  z-index: -1;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

input[type="submit"] {
  -webkit-transition: opacity 0.2s ease, background 0.2s ease;
  transition: opacity 0.2s ease, background 0.2s ease;
  display: block;
  opacity: 0;
  margin: 10px 0 0 0;
  padding: 10px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background: #EEE;
}

input[type="submit"]:active {
  background: #FFFFFF;
}

input._question_nmpc8f:valid~input[type="submit"],
textarea._question_nmpc8f:valid~input[type="submit"] {
  -webkit-animation: _appear_nmpc8f 1s forwards;
  animation: _appear_nmpc8f 1s forwards;
}

input._question_nmpc8f:invalid~input[type="submit"],
textarea._question_nmpc8f:invalid~input[type="submit"] {
  display: none;
}

@-webkit-keyframes _appear_nmpc8f {
  100% {
    opacity: 1;
  }
}

@keyframes _appear_nmpc8f {
  100% {
    opacity: 1;
  }
}

/* styles for nullify-website/styles/contribute/resource-tags.scss */
input:focus {
  outline: 0;
}

input._question_162wbc,
textarea._question_162wbc {
  font-size: 48px;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  width: 80%;
  background: rgba(0, 0, 0, 0);
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden;
  /* Hack to make "rows" attribute apply in Firefox. */
}

/* Underline and Placeholder */

input._question_162wbc+label,
textarea._question_162wbc+label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 10%;
  border-top: 1px solid red;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}

input._question_162wbc:focus+label,
textarea._question_162wbc:focus+label {
  width: 80%;
}

input._question_162wbc:focus,
input._question_162wbc:valid {
  padding-top: 35px;
}

textarea._question_162wbc:valid,
textarea._question_162wbc:focus {
  margin-top: 35px;
}

input._question_162wbc:focus+label>span,
input._question_162wbc:valid+label>span {
  top: -100px;
  font-size: 22px;
  color: #FFFFFF;
}

textarea._question_162wbc:focus+label>span,
textarea._question_162wbc:valid+label>span {
  top: -150px;
  font-size: 22px;
  color: #FFFFFF;
}

input._question_162wbc:valid+label,
textarea._question_162wbc:valid+label {
  border-color: green;
}

input._question_162wbc:invalid,
textarea._question_162wbc:invalid {
  box-shadow: none;
}

input._question_162wbc+label>span,
textarea._question_162wbc+label>span {
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #FFFFFF;
  font-size: 48px;
  top: -66px;
  left: 0px;
  z-index: -1;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

input[type="submit"] {
  -webkit-transition: opacity 0.2s ease, background 0.2s ease;
  transition: opacity 0.2s ease, background 0.2s ease;
  display: block;
  opacity: 0;
  margin: 10px 0 0 0;
  padding: 10px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background: #EEE;
}

input[type="submit"]:active {
  background: #FFFFFF;
}

input._question_162wbc:valid~input[type="submit"],
textarea._question_162wbc:valid~input[type="submit"] {
  -webkit-animation: _appear_162wbc 1s forwards;
  animation: _appear_162wbc 1s forwards;
}

input._question_162wbc:invalid~input[type="submit"],
textarea._question_162wbc:invalid~input[type="submit"] {
  display: none;
}

@-webkit-keyframes _appear_162wbc {
  100% {
    opacity: 1;
  }
}

@keyframes _appear_162wbc {
  100% {
    opacity: 1;
  }
}

/* styles for nullify-website/styles/contribute/resource-url.scss */
input:focus {
  outline: 0;
}

input._question_1imoah,
textarea._question_1imoah {
  font-size: 48px;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  width: 80%;
  background: rgba(0, 0, 0, 0);
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden;
  /* Hack to make "rows" attribute apply in Firefox. */
}

/* Underline and Placeholder */

input._question_1imoah+label,
textarea._question_1imoah+label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 10%;
  border-top: 1px solid red;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}

input._question_1imoah:focus+label,
textarea._question_1imoah:focus+label {
  width: 80%;
}

input._question_1imoah:focus,
input._question_1imoah:valid {
  padding-top: 35px;
}

textarea._question_1imoah:valid,
textarea._question_1imoah:focus {
  margin-top: 35px;
}

input._question_1imoah:focus+label>span,
input._question_1imoah:valid+label>span {
  top: -100px;
  font-size: 22px;
  color: #FFFFFF;
}

textarea._question_1imoah:focus+label>span,
textarea._question_1imoah:valid+label>span {
  top: -150px;
  font-size: 22px;
  color: #FFFFFF;
}

input._question_1imoah:valid+label,
textarea._question_1imoah:valid+label {
  border-color: green;
}

input._question_1imoah:invalid,
textarea._question_1imoah:invalid {
  box-shadow: none;
}

input._question_1imoah+label>span,
textarea._question_1imoah+label>span {
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #FFFFFF;
  font-size: 48px;
  top: -66px;
  left: 0px;
  z-index: -1;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

input[type="submit"] {
  -webkit-transition: opacity 0.2s ease, background 0.2s ease;
  transition: opacity 0.2s ease, background 0.2s ease;
  display: block;
  opacity: 0;
  margin: 10px 0 0 0;
  padding: 10px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background: #EEE;
}

input[type="submit"]:active {
  background: #FFFFFF;
}

input._question_1imoah:valid~input[type="submit"],
textarea._question_1imoah:valid~input[type="submit"] {
  -webkit-animation: _appear_1imoah 1s forwards;
  animation: _appear_1imoah 1s forwards;
}

input._question_1imoah:invalid~input[type="submit"],
textarea._question_1imoah:invalid~input[type="submit"] {
  display: none;
}

@-webkit-keyframes _appear_1imoah {
  100% {
    opacity: 1;
  }
}

@keyframes _appear_1imoah {
  100% {
    opacity: 1;
  }
}

/* styles for nullify-website/styles/flag.scss */
._bottom_8o5tjb {
	position: absolute;
	bottom: 0;
}

._bottom_8o5tjb > span {
	font-size: 10px;
}
/* styles for nullify-website/styles/hsctf-old.scss */
._leftside_1vo4t9, ._rightside_1vo4t9 {
	height: 50vh;
	width: 100%;
}

@media screen and (min-width: 768px) {
	._leftside_1vo4t9, ._rightside_1vo4t9 {
		height: 100vh;
	}
}

._pokemon-right_1vo4t9 {
  background-image: url('/assets/images/pokemonblur.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

._outline_1vo4t9 {
	text-shadow: -1px -1px 0 #000,
				1px -1px 0 #000,
				-1px 1px 0 #000,
				1px 1px 0 #000;
}

._display-t_1vo4t9 {
  color: white;
}

._display-t_1vo4t9 h1 {
  margin-bottom: 20px;
  font-size: 40px;
  line-height: 1.5;
  font-family: "Space Mono", Arial, serif;
  font-weight: 300;
}

._display-t_1vo4t9 h6 {
  font-family: "Space Mono", Arial, serif;
}

._circle_1vo4t9 {
  background-color: #4F4F51;
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

#_countdown_1vo4t9 p {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
}

#_countdown_1vo4t9 ._canvas-container_1vo4t9 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 440px;
  margin: 0 auto;
}

@media (max-width:500px) {
  #_countdown_1vo4t9 ._canvas-container_1vo4t9 {
    max-width: 220px;
  }

  #_countdown_1vo4t9 canvas:nth-child(-n+2) {
    margin-bottom: 10px;
  }
}

._logo_1vo4t9 {
	width: 20vw;
	min-width: 300px;
	margin-bottom: 30px;
}

._countdown_1vo4t9 {
	padding-top: 15px;
}

._live_1vo4t9 {
	margin-top: 50px;
}
/* styles for nullify-website/styles/hsctf.scss */
@font-face {
  font-family: 'Stencil Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Stencil Regular'), url('/assets/fonts/STENCIL.woff') format('woff');
}

._courier_6k46m9 {
	font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
	font-style: normal;
	font-variant: normal;
	color: #000000;
	// font-weight: 700;
	// line-height: 26.4px;
}

._stencil_6k46m9 {
	font-family: 'Stencil Regular';
}

._background_6k46m9 {
	background-image: url("/assets/images/wood.jpg");
	background-position: center;
	background-size: cover;
}

@media (min-width: 768px) {
	._paper_6k46m9 {
		background-image: url("/assets/images/paper.png");
		background-color: #FFFFEE;
		background-position: right top;
		background-size: cover;
	}
}

@media (max-width: 767px) {
  	._paper_6k46m9 {
		background-image: url("/assets/images/paper-plain.png");
		background-color: #FFFFEE;
		background-position: right top;
		background-size: cover;
	}
}

._hr_6k46m9 {
  background-color: #000000;
  border: 1px solid black;
  border-radius: 7px 7px 7px 7px;
}

._black_6k46m9 {
	color: #000000 !important;
}

._confidential_6k46m9 {
	color: #000000 !important;
	background-color: #000000 !important;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	word-wrap: break-word;
}

._input-group-header_6k46m9{
	background: rgba(0, 0, 0, 0) !important;
	border: none !important;
	border-bottom: 1px solid #000000 !important;
	border-radius: 0px !important;
}

._input-group-field_6k46m9 {
	background: rgba(0, 0, 0, 0) !important;
	border: none !important;
	border-bottom: 1px solid #000000 !important;
	border-radius: 0px !important;
	color: #000000 !important;
}

._submit-button_6k46m9 {
	background: rgba(0, 0, 0, 0) !important;
	border: 1px solid #000000 !important;
	border-radius: 0px !important;
	color: #000000 !important;
}

._submit-button_6k46m9:hover {
  background: rgba(0, 0, 0, 1) !important;
  border: 1px solid #000000 !important;
  border-radius: 0px !important;
  color: #FFFFFF !important;
}

._submit-button-green_6k46m9 {
  background: #27963C !important;
  border: 1px solid #000000 !important;
  border-radius: 0px !important;
  color: #000000 !important;
}
/* styles for nullify-website/styles/index.scss */
._gradient-background_1mfahq {
  background: -webkit-linear-gradient(315deg, rgba(39, 150, 60, 0.5) 0%, rgba(48, 48, 48, 1) 80%); /* Chrome 10+, Saf5.1+ */
  background: -moz-linear-gradient(315deg, rgba(39, 150, 60, 0.5) 0%, rgba(48, 48, 48, 1) 80%); /* FF3.6+ */
  background: -ms-linear-gradient(315deg, rgba(39, 150, 60, 0.5) 0%, rgba(48, 48, 48, 1) 80%); /* IE10 */
  background: -o-linear-gradient(315deg, rgba(39, 150, 60, 0.5) 0%, rgba(48, 48, 48, 1) 80%); /* Opera 11.10+ */
  background: linear-gradient(315deg, #27963c80 0%, #303030 80%); /* W3C */
  animation: _gradient_1mfahq 10s linear infinite;
  background-size: 200%, 100%;
  animation-direction: alternate;
}

@keyframes _gradient_1mfahq {
  0% {
    background-position: 0%
  }

  100% {
    background-position: 50%
  }
}

._banner_1mfahq {
	margin-top: 8vh;
	margin-bottom: 8vh;
	font-size: 21px;
}

/* styles for nullify-website/styles/matrix.scss */
._hide_v20mz4 {
  display: none;
}

#_matrix_v20mz4 {
  background-color: #3e3e56;
  -webkit-animation: _blur_v20mz4 10s infinite;
  animation: _blur_v20mz4 10s infinite;
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
}

@-webkit-keyframes _blur_v20mz4 {
  0% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  2% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  5% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  7% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  10% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  12% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  58% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  60% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  62% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  65% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  67% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  69% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  72% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }
}

@keyframes _blur_v20mz4 {
  0% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  2% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  5% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  7% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  10% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  12% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  58% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  60% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  62% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  65% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  67% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  69% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  72% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }
}
/* styles for nullify-website/styles/meeting.scss */
h5#checkinstatus>svg>path {
	fill: green;
}
/* styles for nullify-website/styles/resources.scss */
._hide_hsbviq {
  display: none;
}

#_matrix_hsbviq {
  background-color: #3e3e56;
  -webkit-animation: _blur_hsbviq 10s infinite;
  animation: _blur_hsbviq 10s infinite;
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
}

._card-link_hsbviq {
	color: inherit;
	text-decoration: none;
}

._has-search_hsbviq > input {
  padding-left: 2.375rem;
}

._has-search_hsbviq > span {
  position: absolute;
  z-index: 5;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

._search_hsbviq {
	border-radius: 0.25rem !important;
	// border-top-left-radius: 0.25rem !important;
}

@-webkit-keyframes _blur_hsbviq {
  0% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  2% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  5% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  7% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  10% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  12% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  58% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  60% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  62% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  65% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  67% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  69% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  72% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }
}

@keyframes _blur_hsbviq {
  0% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  2% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  5% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  7% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  10% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  12% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  58% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  60% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  62% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  65% {
    -webkit-filter: url("#blur-horizontal");
    filter: url("#blur-horizontal");
  }

  67% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }

  69% {
    -webkit-filter: url("#blur-vertical");
    filter: url("#blur-vertical");
  }

  72% {
    -webkit-filter: url("#none");
    filter: url("#none");
  }
}