$font-size: 16px !default;
$border-radius: 3px !default;
$date-picker-color-primary: #2ecc71 !default;
$date-picker-color-secondary: #ccc !default;
$date-picker-color-background: #eee !default;

/* Date Picker Begin */
.date-picker__wrapper {
  display: inline-block;
  position: relative;
}

.date-picker {
  padding: 15px;
  background: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: $border-radius;
  font-size: $font-size;
}

.date-picker__trigger {
  outline: 0;
}

.date-picker__button {
  min-width: 110px;
  text-align: center;
}

.date-picker__button--focus {
  border-color: $date-picker-color-primary;
}

.date-picker__header {
  text-align: center;
  margin-bottom: 10px;
}

.date-picker__header__title {
  font-weight: bold;
  font-size: 1em;
  line-height: 30px;
}

.date-picker__header__button {
  cursor: pointer;
  box-shadow: none;
  outline: none;
  background: none;

  border: 1px solid $date-picker-color-secondary;
  padding: 3px 15px;
  border-radius: $border-radius;
}

.date-picker__header__button:hover,
.date-picker__header__button:focus {
  background: $date-picker-color-primary;
  color: white;
  border-color: $date-picker-color-primary;
}

.date-picker__header__button--previous {
  float: left;
}

.date-picker__header__button--next {
  float: right;
}

.date-picker__calendar__outer {
  width: 250px;
}

.date-picker__weekdays:after,
.date-picker__calendar:after {
  content: '';
  clear: left;
  display: block;
}

.date-picker__day,
.date-picker__weekday,
.date-picker__day__placeholder {
  display: inline-block;
  width: 14.2857142857%; /* 100% / 7 */
  margin: 0;
  padding: 2px 0;
  float: left;
  text-align: center;
}

.date-picker__weekday {
  font-size: 0.9em;
}

.date-picker__calendar {
  border-radius: $border-radius;
}

.date-picker__day {
  position: relative;
  border: 0;
  box-shadow: none;
  background: none;
  line-height: 2em;
  background-color: $date-picker-color-background;
  border-radius: $border-radius;

  border-color: white;
  border-style: solid;

  border-top-width: 1px;
  border-left-width: 1px;
}

.date-picker__day:nth-child(7n) {
  border-right-width: 1px;
}

.date-picker__day:last-of-type {
  border-right-width: 1px;
}

.date-picker__day:hover,
.date-picker__day:focus {
  outline: none;
  z-index: 2;
  box-shadow: inset 0 0 1px 2px darken($date-picker-color-primary, 25%);
}

.date-picker__day--today {
  color: $date-picker-color-primary;
}

.date-picker__day--today.date-picker__day--selected,
.date-picker__day--today.date-picker__day--in-range {
  color: black;
}

.date-picker__day--selected {
  background-color: $date-picker-color-primary;
  color: white;
  font-weight: bold;
}

.date-picker__day--disabled[disabled] {
  color: $date-picker-color-secondary;
  cursor: auto;
}

.date-picker__day--disabled[disabled]:hover,
.date-picker__day--disabled[disabled]:focus {
  background-color: $date-picker-color-background;
  color: $date-picker-color-secondary;
}

.date-picker__day--in-range {
  background-color: lighten($date-picker-color-primary, 10%);
  color: white;
}

.date-picker--inline {
  position: relative;
  box-shadow: none;
}

.date-picker--options {
  white-space: nowrap;
}

.date-picker--options .date-picker__calendar {
  display: inline-block;
  vertical-align: top;
}

.date-picker__options {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
}

.date-picker__options__button {
  display: block;
  width: 100%;
  margin: 0 0 10px 0;
}

/* Date Picker End */

/* Month/Year Picker Begin */
.date-picker__header__select {
  display: inline-block;
}

.date-picker__header__select:focus {
  outline: none;
}

.date-picker__header__select__content {
  max-height: 220px;
  overflow-y: auto;
  box-shadow: 0 1px 2px 2px $date-picker-color-secondary;
}

.date-picker__header__select__item {
  display: block;
  width: 100%;
  padding: 2px 10px;
  border: 0;
  border-bottom: 1px solid $date-picker-color-secondary;
  box-shadow: none;
  background: none;
}

.date-picker__header__select__item:last-child {
  border: 0;
}

.date-picker__header__select__item--current {
  background: $date-picker-color-primary;
  color: white;
}

/* Month/Year Picker End */

/* Time Picker Begin */

.time-picker__wrapper {
  display: inline-block;
  position: relative;
  min-width: 7em;
  box-sizing: border-box;
}

.time-picker__button,
.time-picker__input {
  width: 100%;
  white-space: nowrap;
}

.time-picker__dropdown {
  width: 100%;
  max-height: 300px;
  background: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: $border-radius;
  font-size: $font-size;
  overflow-y: auto;
  text-align: left;
}

.time-picker__dropdown__item {
  padding: 3px;
  background: white;
  color: #000;
  cursor: pointer;
}

.time-picker__dropdown__item--selected {
  background-color: $date-picker-color-primary;
  color: #fff;
}

.time-picker__dropdown__item:hover,
.time-picker__dropdown__item:focus {
  background-color: lighten($date-picker-color-primary, 10%);
  color: #fff;
}

/* Time Picker End */
